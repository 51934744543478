import React from "react";
import { Route, Redirect } from "react-router-dom";
import MainLayoutComponent from "./MainLayout";
import Cookies from "js-cookie";
import { getEnv, authRedirection } from "./redirection";
const queryString = require("query-string");

export const MainLayout = (component) => {
  return <MainLayoutComponent>{component}</MainLayoutComponent>;
};

export const NoLayout = (component) => {
  return <>{component}</>;
};

export const PrivateRoute = ({
  component: Component,
  path,
  isPublic,
  ...rest
}) => {
  const isAuthenticated = Cookies.get("token", { domain: getEnv() });
  return (
    <Route
      {...rest}
      render={(props) =>
        isPublic && path.split("/")[1] !== "auth"
          ? MainLayout(<Component {...props} />)
          : isAuthenticated
          ? path.split("/")[1] === "auth"
            ? MainLayout(
                authRedirection(
                  queryString.parse(window.location.search).domain
                )
              )
            : MainLayout(<Component {...props} />)
          : path.split("/")[1] === "auth"
          ? NoLayout(<Component {...props} />)
          : NoLayout(<Redirect to={{ pathname: "/auth/login" }} />)
      }
    />
  );
};
