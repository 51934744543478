import {axiosInstance} from '../../network/apis';

const signupRequest = async (data) => {
    return await axiosInstance.post('/candidate/SignUp', data);
}
const sendEmailAgainRequest = async (data) => {
    return await axiosInstance.post('/candidate/resend_confirm_email', data);
}
const confirmEmailRequest = async (data) => {
    return await axiosInstance.post('/candidate/confirmEmail', data);
}
const signinRequest = async (data) => {
    return await axiosInstance.post('/candidate/login', data);
}

const forgetPasswordRequest = async (data) =>{
    return await axiosInstance.post('/candidate/forget_password' , data)
}

const resetPasswordRequest = async (data) =>{
    return await axiosInstance.post('/candidate/reset_password' , data )
}
const socialLoginsRequest = async (data) =>{
    return await axiosInstance.post('/candidate/SocialSignUp' , data )
}

const confirmLoginOTPRequest = async (data) =>{
    return await axiosInstance.post('/candidate/ConfirmLoginOTP' , data )
}

const resendLoginOTPRequest = async (data) =>{
    return await axiosInstance.post('/candidate/ResendLoginOTP' , data )
}

export default {
    signupRequest ,
    forgetPasswordRequest,
    sendEmailAgainRequest,
    confirmEmailRequest,
    resetPasswordRequest,
    signinRequest,
    socialLoginsRequest,
    confirmLoginOTPRequest,
    resendLoginOTPRequest
};