import React from "react";
import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import messages from "../../assets/Local/messages";
import logo from "../../assets/images/logo.png";

function Footer() {
  const lang = useSelector((state) => state.lang);
  const message = messages[lang];

  return (
    <div className="footer_primary_bg">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <img src={logo} alt="HRCom logo" className="footer-logo" />
            <p className=" mt-3 medium_font">info@hrcom.io</p>
            <p className="medium_font" dir="ltr">
              920009674
            </p>
          </div>
          <div className="col-lg-4 col-md-6">
            <h6 className="footer_titles my-3  bold_font">
              {message.footer.aboutUs}
            </h6>
            <Link to="/privacyPolicy">
              <p className=" mb-0  w-75 medium_font">
                {message.footer.privacyPolicy}
              </p>
            </Link>
            <Link to="/termsAndConditions">
              <p className=" mb-0  w-75 medium_font">
                {message.footer.termsAndConditions}
              </p>
            </Link>
            <Link to="/disclaimer">
              <p className=" mb-0  w-75 medium_font">
                {message.footer.disclaimer}
              </p>
            </Link>
          </div>
          <div className="col-lg-4 col-md-6">
            <h6 className="footer_titles my-3  bold_font">
              {message.footer.addressTitle}
            </h6>
            <p className="medium_font mb-0  my-3 w-75">
              <span className="d-block">HRCom</span>
              {message.footer.address}
            </p>
            <div className="d-flex mt-4">
              <a
                href="https://www.facebook.com/HRcom-110752133832994/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMediaCircle d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faFacebookF} />
                </div>
              </a>
              <a
                href="https://www.linkedin.com/company/hrcom-io"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMediaCircle d-flex align-items-center justify-content-center mx-3">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </div>
              </a>
              <a
                href="https://twitter.com/HRcomio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMediaCircle d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faTwitter} />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
