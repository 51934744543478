import { call, put, takeLatest } from 'redux-saga/effects';
import API from './apis';
import * as TYPE from './types';
import * as ACTION from './actions';
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from '../../utils/Shared';

export function* getTopCompanies({ payload }) {
  try {
    let response = {};
    response = yield call(API.getTopCompanies, payload);
    if (response.data?.data.length < 7) {
      yield put(ACTION.resetTopCompanies(true));
      response = yield call(API.getTopCompanies, {
        page_size: 7,
        page_number: 1,
      });
      yield put(ACTION.resetTopCompanies(false));
    }
    yield put(ACTION.getTopCompaniesRecieve(response.data.data));
    yield put(ACTION.hideTopCompanies(true));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* allowsSharingCandidateData({ payload }) {
  try {
    yield call(API.allowsSharingCandidateData, payload);
    yield put(ACTION.allowCandidateSharingRecieve());
    localStorage.setItem('shareStatusDisplayed', true);
    dispatchSnackbarSuccess('savedSuccessfuly');
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* HomeSagasWatch() {
  yield takeLatest(TYPE.GET_TOP_COMPANIES_REQUEST, getTopCompanies);
  yield takeLatest(TYPE.SHARE_CANDIDATE_REQUEST, allowsSharingCandidateData);
}
