import Cookies from "js-cookie";
import { getEnv } from "./redirection";
const Auth = {
  signOut() {
    Cookies.remove('userData', { domain : getEnv() });
    Cookies.remove('token', { domain : getEnv() });
  },
  isAuth() {
    return Cookies.get("token" , { domain : getEnv() });
  },
};
export default Auth;
