import { all } from "redux-saga/effects";
import { AuthSagasWatch } from "../Auth/sagas";
import { getAllCompanies } from "../AllCompanies/sagas";
import { lookupsSagasWatch } from "../Lookups/sagas";
import { followedCompanies } from "../FollowedCompanies/sagas";
import { getBrowseByIndustry } from "../BrowseByIndustry/sagas";
import { industriesSagas } from "../AllIndustries/sagas";
import { jobsSagas } from "../Jobs/sagas";
import { ProfileSagasWatch } from "../Profile/sagas";
import { ScreeningQuestionsSagasWatch } from "../ScreenQuestions/sagas";
import { ContactUsSagas } from "../ContactUs/sagas";
import { HomeSagasWatch } from '../Home/sagas';
import {NotificationSagasWatch} from "../Notification/saga"

export function* watchSagas() {
  yield all([
    AuthSagasWatch(),
    getAllCompanies(),
    lookupsSagasWatch(),
    followedCompanies(),
    getBrowseByIndustry(),
    industriesSagas(),
    jobsSagas(),
    ProfileSagasWatch(),
    ScreeningQuestionsSagasWatch(),
    ContactUsSagas(),
    HomeSagasWatch(),
    NotificationSagasWatch()
  ]);
}
