import { combineReducers } from "redux";
import lang from "../Lang/reducer";
import loader from "../Loader/LoaderReducer";
import snackbar from "../Snackbar/SnackbarReducer";
import lookups from "../Lookups/reducer";
import auth from "../Auth/reducer";
import allCompanies from "../AllCompanies/reducer";
import jobs from "../Jobs/reducer";
import followedCompanies from "../FollowedCompanies/reducer";
import browseByIndustry from "../BrowseByIndustry/reducer";
import industries from "../AllIndustries/reducer";
import profile from "../Profile/reducer";
import contactUs from "../ContactUs/reducer";
import modalState from '../Modal/reducer';
import screeningQuestions from '../ScreenQuestions/reducer'
import HomeRequests from "../Home/reducer";
import notification from "../Notification/reducer"
export default combineReducers({
  lang,
  loader,
  contactUs,
  modalState,
  snackbar,
  lookups,
  auth,
  allCompanies,
  screeningQuestions,
  jobs,
  followedCompanies,
  browseByIndustry,
  industries,
  profile,
  home: HomeRequests,
  notification
});
