import store from "../store";
import moment from "moment-timezone";
import { showSnackbarAction } from "../store/Snackbar/SnackbarAction";
import messages from "../assets/Local/messages";
import History from "../routes/History";
const queryString = require("query-string");

export function dispatchSnackbarError(data) {
  if (data) {
    const errorMsg = data.error?.message;
    store.dispatch(showSnackbarAction(errorMsg, "error"));
  }
}
export function dispatchSnackbarSuccess(message) {
  const lang = store.getState().lang;
  store.dispatch(
    showSnackbarAction(messages[lang].snackbar[message], "success")
  );
}

export const getChangedFields = (values) => {
  let cloneValues = { ...values };
  Object.keys(values).forEach((key) => {
    if (
      values[key] === false ||
      (typeof values[key] === "string" && values[key].trim() === "")
    ) {
      delete cloneValues[key];
    }
  });
  return cloneValues;
};
export function getDomain() {
  return "";
}
export const getYesterdayDate = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

export function getURLParams(paramName) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
}


// export const getEnv = () => {
//   if (window.location.href.includes("hrcom.ibtikar.sa")) {
//     return "hrcom.ibtikar.sa";
//   } else if (window.location.href.includes("hrcom.co")) {
//     return `hrcom.co`;
//   } else if (window.location.href.includes("stage-oc")) {
//     return `stage-oc.hrcom.site`;
//   } else if (window.location.href.includes("prelive-oc")) {
//     return `prelive-oc.hrcom.site`;
//   } else if (window.location.href.includes("stage.hrcom.site")) {
//     return "stage.hrcom.site";
//   } else if (window.location.href.includes("hrcom.site")) {
//     return "hrcom.site";
//   } else if (window.location.href.includes("hrcom.io")) {
//     return `hrcom.io`;
//   } else {
//     return "localhost";
//   }
// };

// export const getAdminPortal = () => {
//   const lang = store.getState().lang;
//   if (window.location.href.includes("hrcom.ibtikar.sa")) {
//     return `https://admin-stage-hrcom.ibtikar.sa?locale=${lang}`;
//   } else if (window.location.href.includes("hrcom.co")) {
//     return `https://hrcom.co?locale=${lang}`;
//   } else if (window.location.href.includes("stage-oc")) {
//     return `https://stage-oc.hrcom.site?locale=${lang}`;
//   } else if (window.location.href.includes("prelive-oc")) {
//     return `https://prelive-oc.hrcom.site?locale=${lang}`;
//   } else if (window.location.href.includes("stage.hrcom.site")) {
//     return `https://admin-stage.hrcom.site?locale=${lang}`;
//   } else if (window.location.href.includes("hrcom.site")) {
//     return `https://admin.hrcom.site?locale=${lang}`;
//   } else if (window.location.href.includes("hrcom.io")) {
//     return `https://hrcom.io?locale=${lang}`;
//   }
// };
// export const authRedirection = (domain) => {
//   if (!domain) {
//     window.location.href = `/`;
//   } else {
//     window.location.href = getCandidatePortalHome(domain);
//   }
// };
// export const getCandidatePortalHome = (domain) => {
//   if (window.location.href.includes("hrcom.ibtikar.sa")) {
//     return `https://${domain}-candidate-stage.hrcom.ibtikar.sa`;
//   } else if (window.location.href.includes("hrcom.co")) {
//     return `https://${domain}.candidates.hrcom.co`;
//   } else if (window.location.href.includes("stage-oc")) {
//     return `https://${domain}.candidates.stage-oc.hrcom.site`;
//   } else if (window.location.href.includes("prelive-oc")) {
//     return `https://${domain}.candidates.prelive-oc.hrcom.site`;
//   } else if (window.location.href.includes("stage.hrcom.site")) {
//     return `https://${domain}-candidate.stage.hrcom.site`;
//   } else if (window.location.href.includes("hrcom.site")) {
//     return `https://${domain}.candidate.hrcom.site`;
//   } else if (window.location.href.includes("hrcom.io")) {
//     return `https://${domain}.candidate.hrcom.io`;
//   }
// };
export const getAutoCompleteFields = (arr, id) => {
  return (
    arr.filter((item) => item.id == id)[0] || {
      id: "",
      name_en: "",
      name_ar: "",
    }
  );
};

export const domainOfCandidatePortal = () => {
  const param = queryString.parse(window.location.search);
  return param.domain || null;
};

export const updateURLocalization = (l) => {
  const locale = getURLParams("locale");
  if (locale) {
    History.push({
      pathname: History.location.pathname,
      search: `?locale=${l}`,
    });
  }
};

export const blobToBase64 = async (blob, fn) => {
  return new Promise((resolve, onerror) => {
    //represent the  blob image as a file,
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function (event) {
      resolve(event.target.result);
    };
  });
};

export const isToday = (pastDate) => {
  const formatedGiven = moment.utc(pastDate).local().format("YYYY-MM-DD");
  const given = moment(formatedGiven, "YYYY-MM-DD");
  const current = moment().startOf("day");
  return moment.duration(given.diff(current)).asDays() === 0 ? true : false;
};
