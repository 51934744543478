import { call, put, takeLatest } from "redux-saga/effects";
import API from "./apis";
import * as ACTION from "./actions";
import * as TYPE from "./types";
import History from "./../../routes/History";
import ReactGA from "react-ga";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";

export function* jobScreeningQuestions({ payload }) {
  try {
    const response = yield call(API.jobScreeningQuestions, payload);
    yield put(ACTION.getScreeningQuestionReceive(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* applyJobRequest({ payload }) {
  let lang = localStorage.getItem("lang");
  try {
    const response = yield call(API.applyJobRequest, payload);
    yield put(ACTION.applyJobReceive(response.data.data));
    dispatchSnackbarSuccess("successApply");
    // History.push(`/jobs`);
    ReactGA.event({
      category: `${localStorage.getItem("domain")} : Apply to jobs `,
      action: "User pressed apply to jobs button",
    });
     History.push({
      state: {
        code: null,
      },
    })
  } catch (err) {
    if (err.response?.data?.error?.code) {
      if (err.response.data.error.code === 2) {
        History.push(`/screeningQuestions/${payload.job_request_id}`);
        History.push({
          state: {
            code: 2,
            errorMsg: null,
          },
        });
      } else if (err.response.data.error.code === 1) {
        // History.location.code = 1;
        let errorAr =
          err.response.data.error.message +
          " (" +
          err.response.data.error.requiredfieldsar +
          ")";
        let errorEn =
          err.response.data.error.message +
          " (" +
          err.response.data.error.requiredfieldsen +
          ")";
        lang === "en"
          ? History.push({
              state: {
                errorMsg: errorEn,
                code: 1,
              },
            })
          : History.push({
              state: {
                errorMsg: errorAr,
                code: 1,
              },
            });
        // dispatchError(err.response.data);
      }
    } else {
      History.push({
        state: {
          code: null,
        },
      });
      dispatchSnackbarError(err.response?.data);
    }
  }
}

export function* ScreeningQuestionsSagasWatch() {
  yield takeLatest(
    TYPE.GET_JOB_SCREENING_QUESTIONS_REQUEST,
    jobScreeningQuestions
  );
  yield takeLatest(TYPE.APPLY_JOB_REQUEST, applyJobRequest);
}
