import React, { Suspense } from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from "./History";
import Loader from "../components/Loader/Loader";
import { PrivateRoute } from "../utils/PrivateRoute";
import * as LazyComponent from "../utils/LazyLoaded";
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';


const Routes = (
  <Suspense fallback={<Loader />}>
    <Router history={history}>
      <Switch>
      <Route exact path="/linkedin" component={LinkedInPopUp} />

        <LazyComponent.ScreeningQuestions
          path="/screeningQuestions/:id?"
          exact
        />
        <PrivateRoute
          path="/"
          isPublic={true}
          exact
          component={LazyComponent.Home}
        />
        <PrivateRoute
          path="/Companies"
          isPublic={true}
          exact
          component={LazyComponent.Companies}
        />
        <PrivateRoute
          path="/jobs/"
          isPublic={true}
          component={LazyComponent.AllJobs}
        />
        <PrivateRoute
          path="/privacyPolicy"
          isPublic={true}
          exact
          component={LazyComponent.PrivacyPolicy}
        />
        <PrivateRoute
          path="/termsAndConditions"
          isPublic={true}
          exact
          component={LazyComponent.TermsAndConditions}
        />
        <PrivateRoute
          path="/cities"
          isPublic={true}
          exact
          component={LazyComponent.AllCities}
        />
        <PrivateRoute
          path="/industries"
          isPublic={true}
          exact
          component={LazyComponent.AllIndustries}
        />
        <PrivateRoute
          path="/auth/signup"
          exact
          component={LazyComponent.Signup}
        />
        <PrivateRoute
          path="/auth/success"
          isPublic={true}
          exact
          component={LazyComponent.SignupSuccess}
        />
        <PrivateRoute
          path="/auth/resetPassword"
          isPublic={true}
          exact
          component={LazyComponent.ResetPassword}
        />
        <PrivateRoute
          path="/auth/forgetpassword"
          isPublic={true}
          exact
          component={LazyComponent.ForgetPassword}
        />
        <PrivateRoute
          path="/auth/ConfirmEmail"
          exact
          component={LazyComponent.ResetPassword}
        />
        <PrivateRoute
          path="/auth/login"
          exact
          component={LazyComponent.Login}
        />
        <PrivateRoute
          path="/auth/otp/:userId"
          exact
          component={LazyComponent.OTP}
        />
        <PrivateRoute
          path="/disclaimer"
          isPublic={true}
          exact
          component={LazyComponent.Disclaimer}
        />
        <PrivateRoute
          path="/jobPreferences"
          exact
          component={LazyComponent.JobPreferences}
        />
        <PrivateRoute path="/myJobs" exact component={LazyComponent.MyJobs} />
        <PrivateRoute
          path="/profile"
          isPublic={true}
          exact
          component={LazyComponent.MyProfile}
        />
          <PrivateRoute
          path="/notificationList"
          isPublic={true}
          exact
          component={LazyComponent.NotificationList}
        />
        <Route path="/contact-us" exact component={LazyComponent.ContactUs} />

        <PrivateRoute
          path="*"
          exact
          isPublic={true}
          component={LazyComponent.NotFound}
        />
      </Switch>
    </Router>
  </Suspense>
);

export default Routes;
