import store from "../store";

export const getEnv = () => {
    return `.${process.env.REACT_APP_ENV}`;
  };
  
  export const getAdminPortal = () => {
    const lang = store.getState().lang;
    return `${process.env.REACT_APP_ADMIN_URL}?locale=${lang}`;
  };
  export const authRedirection = (domain) => {
    if (!domain) {
      window.location.href = `/`;
    } else {
      window.location.href = getCandidatePortalHome(domain);
    }
  };
  export const getCandidatePortalHome = (domain) => {
    return `https://${domain}${process.env.REACT_APP_CANDIDATE_PORTAL_BASE_URL}`;
  };