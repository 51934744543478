import { call, put } from "redux-saga/effects";
import API from "./apis";
import * as ACTIONS from "./actions";
import { dispatchSnackbarError } from "../../utils/Shared";
import { takeLatest } from "redux-saga/effects";
import * as TYPES from "./types";

// Replace with your sagas
export function* allCompaniesRequest({payload}) {
  try {
    const response = yield call(API.getAllCompanies,payload);
    yield put(ACTIONS.allCompaniesReceive(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}


export function* getAllCompanies() {
  yield takeLatest(TYPES.ALL_COMPANIES_REQUEST, allCompaniesRequest);
}
