import * as types from './types';

export const signupRequest = payload => ({
    type: types.SIGN_UP_REQUEST,
    payload
})

export const forgetPasswordRequest = data => ({
    type: types.FORGET_PASSWORD_REQUEST,
    payload :data
})

export const forgetPasswordReceive = data => ({
    type: types.FORGET_PASSWORD_RECIEVE,
    payload :data
})

export const resetPasswordReceive = payload => ({
    type: types.RESET_PASSWORD_RECIEVE,
    payload
})
export const resetPasswordRequest = payload => ({
    type: types.RESET_PASSWORD_REQUEST,
    payload
})

export const sendEmailAgainRequest = payload => ({
    type: types.SEND_EMAIL_AGAIN_REQUEST,
    payload
})

export const confirmEmailRequest = payload => ({
    type: types.CONFIRM_EMAIL_REQUEST,
    payload
})
export const confirmEmailReceive = payload => ({
    type: types.CONFIRM_EMAIL_RECEIVE,
    payload
})
export const signinRequest = payload => ({
    type: types.SIGN_IN_REQUEST,
    payload
})
export const signinReceive = payload => ({
    type: types.SIGN_IN_RECEIVE,
    payload
})
export const isAuthenticated = (payload) => ({
    type: types.IS_USER_AUTHENTICATED,
    payload
})
export const validateDomainRequest = payload => ({
    type: types.VALIDATE_DOMAIN_REQUEST,
    payload
})
export const validateDomainReceive = () => ({
    type: types.VALIDATE_DOMAIN_RECEIVE
})
export const socialLoginRequest = (payload) => ({
    type: types.SOCIAL_LOGIN_REQUEST,
    payload
})

export const confirmLoginOTPRequest = (payload) => ({
    type: types.CONFIRM_OTP_REQUEST,
    payload
})

export const confirmLoginOTPReceive = (payload) => ({
    type: types.CONFIRM_OTP_RECEIVE,
    payload
})

export const resendLoginOTPRequest = (payload) => ({
    type: types.RESEND_OTP_REQUEST,
    payload
})

export const resendLoginOTPReceive = (payload) => ({
    type: types.RESEND_OTP_RECEIVE,
    payload
})