export default {
  en: {
    poweredBy: "Powered by HRcom",
    hello: "Hello",
    langBtn: "عربى",
    signIn: "Sign in",
    otp: "Verification",
    contactUs: "Contact us",
    contactModalSuccess: "Thank you for your message, we will respond shortly",
    contact_us_title: "Contact us",
    contact_us_content:
      "For more information, fill out the form and we’ll be in touch.",
    signUp: "Sign up",
    privacyPolicy: "Privacy policy",
    termsAndConditions: "Terms and conditions",
    disclaimer: "Disclaimer",
    tabsHeader: {
      personal: "Personal Info",
      advertQuestions: "Advert questions",
      skills: "Skills",
      education: "Education",
      workExperience: "Work Experience",
      reference: "Reference",
      licenses: "Licenses& certifications",
      notifications: "Notifications",
    },
    socialLogin: {
      sectionTitle: "Or continue with",
      googleBtn: "Google",
      linkedinBtn: "Linkedin",
      microsoftBtn: "Microsoft",
    },
    vacancyInner: {
      jobDetailsHeader: "Job details",
      jobLocation: "Job Location",
      apply: "Apply",
      profileErrMsg:
        "Please complete your profile to be able to apply to any vacancy",
      companyBrief: "Company brief",
      completeProfile: "Please Complete your profile",
    },
    educationExperience: {
      college: "College",
      InstituteSchool: "Institute/School",
      degree: "Degree",
      FieldofStudy: "Field of study",
      gpa: "Grade/GPA",
      category: "Category",
      jobTitle: "Job title",
      companyName: "Company name",
      location: "Location",
      from: "From",
      to: "To",
      description: "Description",
      addEducation: "Add education",
      editEducation: "Edit education",
      toPresent: "To present",
      addWorkExperience: "Add work experience",
      editWorkExperience: "Edit work experience",
      present: "Present",
      outOf: "Out of",
      customJobTitle: "Enter job title",
    },
    personalInfo: {
      personalInfoTitle: "Personal information",
      firstName: "First name",
      lastName: "Last name",
      dateOfBirth: "Date of birth",
      residency: "Residence Country",
      city: "Residence City",
      gender: "Gender",
      maritalStatus: "Marital status",
      contactDetails: "Contact details",
      phoneNumber: "Phone number",
      personalEmail: "Personal Email",
      address: "Address",
      male: "Male",
      female: "Female",

      nationality: "Nationality",
    },
    skills: {
      skillsInfoTitle: "Skills",
      profLevel: " Proficiency Level",
      engProLevel: "What is your proficiency level in English?",
      languages: "Languages",
      profLevelInEnglish: " Proficiency level in English",
      motherTongue: "Mother tongue",
    },
    licenses: {
      certificateName: "Certificate Name",
      issuingOrganization: "Issuing organization",
      issuedDate: "Issued date",
      expirationDate: "Expiration date",
      noExpirationDate: "This credential does not expire",
    },
    filter: {
      postedDate: "Posted date",
      jobCategory: "Job category",
      contractType: "Contract type",
      filter: "Apply filter",
      reset: "Reset",
      jobTitle: "Job Title",
      country: "Country",
      city: "City",
      search: "Search",
      resetSearch: "Reset",
    },
    screening: {
      screeningHeader: "Please answer the following questions :",
      date: "Date",
      time: "Time",
      time_type: "Type",
      text: "Answer",
    },
    advertQuestionsInfo: {
      advertQuestionsTitle: "Advert questions",
      currentLastSalary: "Current/Last  Salary",
      currency: "Currency",
      currentLastPosition: "Current/Last position",
      currentLastCompany: "Current/Last company name",
      unemployee: "Unemployed",
      employed: "Employed",
      employeeStatus: "Job status",
    },
    moreInfoQuestions: {
      moreInfoRequest: "More information request",
      answersNeededMsg: "The following questions are required to be filled",
      uploadVideo: "Upload video",
      answerTip: "The answer should be in",
      minutes: "minute(s)",
      letters: "letter(s)",
      expirationDate: "Expire in",
      answer: "Answer",
      submittedBefore: "Thank you! You have already filled this form",
      expiredForm: "the form is expired for now",
      requestChangeingDate: "Request changing date",
      requestChangeDateContent:
        "This request end date is original expiration date, but you can request a change if possible , Please select the date & wait for approval",
      newExpirationDate: "New expiration date",
      reason: "Reason",
      previewVideo: "Play Video",
    },
    navbar: {
      home: "Home",
      jobs: "Jobs",
      companies: "Companies",
      contactUs: "Contact us",
      signIn: "Sign in",
      signUp: "Sign up",
      forCompanies: "For companies",
      myApplications: "My Applications",
    },
    footer: {
      aboutUs: "About us",
      addressTitle: "Address",
      address: "Alnoor Way, Riyadh 12222",
      privacyPolicy: "Privacy policy",
      termsAndConditions: "Terms and conditions",
      disclaimer: "Disclaimer",
    },
    shared: {
      candidatePortal: "Candidate Portal",
      seeAll: "See All",
      done: "Done Successfully",
      copied: "Copied successfully",
      candidateCompany: "Candidate Portal",
      email: "Email Address",
      savedSuccessfuly: "Your data has been saved successfully",
      deactivatedCompany:
        "This Company is not available now. Please try again later !",
      interview: "Interview",
      logout: "Logout",
      profile: "Profile",
      view: "View",
      results: "Results",
      noDataFound: "There is no data found",
      easyApply: "Easy Apply",
      myJobs: "My jobs",
      noJobskeyword: "job",
      oneJobKeyword: "job",
      twoJobs: "jobs",
      morethanTwoJob: "jobs",
      morethantenJobs: "jobs",
      send_again: "Send again",
      address: "Address",
      submit: "Submit",
      message: "message",
      message_placeholder: "Your message here ..",
      register: "Register",
      registeryourCompany: "Register your company",
      add_password_title: "Add password",
      fill_all_form: "*Please fill all fields",
      success_register:
        "A confirmation email has been sent, please check your email. If you didn't receive the confirmation email within 1 minute please click send again",
      notFoundRecords: "No records found",
      name: "Name",
      phone: "Phone",
    },
    alljobs: {
      easyApply: "Easy Apply",
      jobDetails: "Job details",
      apply: "Apply",
      skills: "Recommended Skills",
      postJob: "Post",
    },
    snackbar: {
      successApply: "Thank you for applying, you will hear from us very soon",
      done: "Done Successfully",
      savedSuccessfuly: "Your data has been saved successfully",
      contactModalSuccess:
        "Thank you for contacting us, your message has been sent successfully",
      successFormSubmit: "thank you the form was submitted successfully",
      successApprovedOffer:
        "Thank you for accepting the offer. We are looking forward to start working with you.",
    },
    errors: {
      fillAllForm: "*Please fill all fields",
      required: "This field is required",
      nameError:'This field is should not be contain a special character',
      invalidEmail: "Invalid email",
      invalidPassword:
        "Invalid password : Min 8 characters , a mixture of uppercase and lowercase letter and numbers and special characters",
      confirmPasswordMatch: "Password and confirm password don’t match",
      imageRatio: "The image ratio must be 1:1",
      invalidGPAValue: "It should be between 0 - ",
      toGreaterThanFrom: "To must be greater than from",
      chooseAnswers: "Please check your answer",
      selctedLanguageBefore: "You are already selected this language before",
      pdfWordFilesAllowed: "PDF, Word are only allowed",
      pdfImagesFilesAllowed: "PDF, Word, JPG, PNG, JPEG are only allowed",
      allowedSize: "The allowed file size is 3MB",
      allowedVideoSize: "The allowed video size is",
      videoAllowedTypes: "mp4 is only allowed",
      termsConditionsErr:
        "you must agree to the terms and conditions, privacy policy and disclaimer",
    },
    home: {
      latestJobs: "Latest Jobs",
      jobByCity: "Job By City",
      browseByIndustry: "Browse by industry",
      PostedNewJobs: "+ Posted New Jobs",
      viewJob: "View Job",
      FindYour: "Find Your",
      DreamJobHere: "Dream Job",
      here: "Here",
      byCompanies: "Jobs by Company",
      byCompaniesContnetMiniTitle: "Your career journey starts here!",
      byCompaniesContent:
        "Get advanced! And boost your career prospects by approaching business owners and hiring managers in the best leading companies",
      registeration: {
        title: "Reaching goals starts with action!",
        subTitle:
          "Start your story of success! Register now to achieve your career aspirations",
      },
    },
    Companies: "Companies",
    industries: "Industries",
    browseByIndustry: "Browse by industry",
    seeAll: "see all",
    visitWebsite: " visit website",
    buttons: {
      sendAgain: "Send again",
      copyUrl: "Copy URL",
      completeProfile: "Complete Profile",
      submit: "Submit",
      searchCompanies: "Search Companies",
      register: "Register",
      registerNow: "Register Now",
      login: "Login",
      addSection: "Add section",
      save: "Save",
      cancel: "Cancel",
      downloadAttachment: "Download attached file",
      uploadCv: "Upload CV",
      startInterview: "Start interview",
      uploadFile: "Upload file",
      readMore: "Read More",
      reset: "Reset",
      uploadImage: "Upload image",
      download: "Download",
      accept: "Accept",
      decline: "Decline",
      sendforreview: "Send for review",
      search: "Search",
      resetSearch: "Clear all",
      filter: "Filter",
      apply: "Apply",
      resend: "Resend",
    },
    auth: {
      signup: "Sign up",
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      forgetPassword: "Forgot password ?",
      successRegister1: `A confirmation email has been sent to `,
      successRegister2: ` (Also check your junk or spam folder ), please check your email. If you didn't receive the confirmation email within 1 minute please press "Send again"`,
      password: "Password",
      confirmPassword: "Confirm password",
      passwordValidRules: "Password is following these rules:",
      passwordValidTipMin: "Min. 8 characters",
      passwordValidTipUpperLower:
        "A mixture of both uppercase and lowercase letters",
      passwordValidTipLetterNumber:
        "A mixture of letters, numbers and special characters",
      signin: "Sign in",
      signupDesign: "Sign up to Easy apply for Jobs",
      addPassword: "Add password",
      ForgetPasswordHeading: "Forget Password",
      Reset: "Reset",
      signupMsg: "Don't have an account? Sign up",
      signinMsg: "Already have an account? Sign in",
      hearAboutUs: "How did you hear about us?",
      signUpAgreeMsg: "By registering with us, you are agreeing to our ",
      signUpSharindDataAgreeMsg:
        "By clicking here you are allowing us to share your profile with our clients",
      loginSharindDataAgreeMsg:
        "By clicking allow you are allowing us to share your profile with our clients",
      allow: "Allow",
      disallow: "Don't allow",
      sharing: "Allow sharing",
      otpMessage:
        "A 6-digit OTP has been sent to  your email (please check your email also check your junk or spam folder .)",
    },
    jobPreferences: {
      jobTitle: "Job Title",
      location: "Location (City /Country)",
      jobType: "Job Type",
    },

    filters: {
      country: "Country",
      city: "City",
      name: "Name",
      homeSearchKeyword: "Name or job Title",
      companyNameORjobTitle: "Company name or Job title",
      followedCompanies: "Followed companies",
      unFollowedCompanies: "Unfollow Companies",
      all: "All",
      contractType: "Contract Type",
      sortBy: "Sort by",
      sortOrder: "Sort order",
      easyApplyOnly: "Easy Apply only",
    },
    myJobs: {
      appliedJobs: "Applied jobs",
      savedJobs: "Favorite jobs",
      jobTitle: "Job title",
      status: "Status",
      appliedOn: "Applied on",
      companyName: "Company name",
      companyLogo: "Company logo",
      companyBranch: "Company Branch",
      contractType: "Contract Type",
      postingDate: "Posting date",
      easyToApply: "Easy to apply",
      jobDescription: "Job description",
      applied: "Applied",
      inProgress: "In progress",
      closed: "Closed",
    },
    common: {
      NoDataFound: "No Data Found",
      today: "today",
      days: "Days",
      day: "Day",
      or: "or",
      add: "Add",
    },
  },
};
