import { call, put } from "redux-saga/effects";
import API from "./apis";
import * as ACTION from "./actions";
import { takeLatest } from "redux-saga/effects";
import * as type from "./types";
import { dispatchSnackbarError } from "../../utils/Shared";

export function* getCountries() {
  try {
    const response = yield call(API.getCountriesLookup);
    yield put(ACTION.receiveCountries(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* getCities(action) {
  try {
    const response = yield call(API.getCitiesLookup, action.payload);
    yield put(ACTION.receiveCities(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* getAllJobRolesLookup() {
  try {
    const response = yield call(API.getAllJobRolesLookup);
    yield put(ACTION.receiveAllJobRolesLookup(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* lookupSalaryGrades() {
  try {
    const response = yield call(API.salaryGradesLookup);
    yield put(ACTION.receiveSalaryGradesLookup(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* lookupResidencies() {
  try {
    const response = yield call(API.residenciesLookup);
    yield put(ACTION.receiveResidenciesLookup(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* lookupNationalities() {
  try {
    const response = yield call(API.nationalitiesLookup);
    yield put(ACTION.receiveNationalitiesLookup(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* lookupLanguages() {
  try {
    const response = yield call(API.languagesLookup);
    yield put(ACTION.receiveLanguagesLookup(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* lookupSalaryGradesFilter(action) {
  try {
    const response = yield call(API.salaryGradeFilterLookup, action.payload);
    yield put(ACTION.receiveSalaryGradeFilterLookup(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* lookupCareerLevels() {
  try {
    const response = yield call(API.careerLevelsLookup);
    yield put(ACTION.receiveCareerLevelsLookup(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* lookupCompanyBranches(action) {
  try {
    const response = yield call(API.companyBranchesLookup);
    yield put(ACTION.receiveCompanyBranches(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* lookupJobTitles() {
  try {
    const response = yield call(API.jobtitlesLookup);
    yield put(ACTION.receiveJobTitlesLookup(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* lookupColleges() {
  try {
    const response = yield call(API.collegesLookup);
    yield put(ACTION.receiveCollegesLookup(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* lookupDegrees() {
  try {
    const response = yield call(API.degreesLookup);
    yield put(ACTION.receiveDegreesLookup(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* lookupCompetencies() {
  try {
    const response = yield call(API.competenciesLookup);
    yield put(ACTION.receiveCompetenciesLookup(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* lookupCurrencies() {
  try {
    const response = yield call(API.currencyLookup);
    yield put(ACTION.receiveCurrency(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* lookupJobtitlesCategories() {
  try {
    const response = yield call(API.jobTitlesCategoryLookup);
    yield put(ACTION.receiveJobTitlesCategories(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* agentsLookups() {
  try {
    const response = yield call(API.agentsLookup);
    yield put(ACTION.agentsLookupsRecieve(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* companyFieldsLookups() {
  try {
    const response = yield call(API.companyFieldsLookup);
    yield put(ACTION.companyFieldsLookupsRecieve(response.data?.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* jobOpeningsLookups() {
  try {
    const response = yield call(API.jobOpeningsLookup);
    yield put(ACTION.jobOpeningsLookupReceive(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* pipelineFlowLookups() {
  const response = yield call(API.pipelineFlowsLookup);
  yield put(ACTION.pipelineFlowsLookupReceive(response.data.data));
}

export function* rejectReasonsLookups() {
  const response = yield call(API.rejectionReasonsLookup);
  yield put(ACTION.rejectionReasonsReceive(response.data.data));
}

export function* companySizesLookups() {
  try {
    const response = yield call(API.companySizesLookup);
    yield put(ACTION.companysizesReceive(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* companyTypesLookups() {
  try {
    const response = yield call(API.companyTypesLookup);
    yield put(ACTION.receiveCities(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* hearAboutUsLookups() {
  try {
    const response = yield call(API.getHearAboutUsList);
    yield put(ACTION.hearAboutUsListReceive(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}

export function* getContractType() {
  try {
    const response = yield call(API.getContractType);
    yield put(ACTION.contractTypeReceive(response.data.data));
  } catch (err) {
    dispatchSnackbarError(err.response?.data);
  }
}
export function* countriesLookupRequest() {
  try {
    const response = yield call(API.countriesLookupsRequest);
    yield put(ACTION.countriesLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* citiesLookupRequest({ payload }) {
  try {
    const response = yield call(API.citiesLookupsRequest, payload);
    yield put(ACTION.citiesLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* skillsLookupRequest() {
  try {
    const response = yield call(API.skillsLookupsRequest);
    yield put(ACTION.skillsLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* getUserNationality() {
  try {
    const response = yield call(API.getUserNationality);
    yield put(ACTION.userNationalityReceive(response.data));
  } catch (error) {
    console.log(error);
  }
}
export function* collegeLookupRequest() {
  try {
    const response = yield call(API.collegeLookupRequest);
    yield put(ACTION.collegeLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}
export function* degreeLookupRequest() {
  try {
    const response = yield call(API.degreeLookupRequest);
    yield put(ACTION.degreeLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}
export function* jobTitleLookupRequest() {
  try {
    const response = yield call(API.jobTitleLookupRequest);
    yield put(ACTION.jobTitleLookupReceive(response.data.data));
  } catch (err) {
    console.log(err);
  }
}
export function* jobTitleFilterCategoryLookupRequest({ payload }) {
  try {
    const response = yield call(
      API.jobTitleFilterCategoryLookupRequest,
      payload
    );
    yield put(ACTION.jobTitleFilterCategoryLookupReceive(response.data.data));
  } catch (err) {
    console.log(err);
  }
}
export function* jobTitleCategoryLookupRequest() {
  try {
    const response = yield call(API.jobTitleCategoryLookupRequest);
    yield put(ACTION.jobTitleCategoryLookupReceive(response.data.data));
  } catch (err) {
    console.log(err);
  }
}

export function* residenciesLookupRequest() {
  try {
    const response = yield call(API.residenciesLookupRequest);
    yield put(ACTION.residenciesLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* languagesWithOutEnglishLookupRequest() {
  try {
    const response = yield call(API.languagesWithOutEnglish);
    yield put(ACTION.languagesWithOutEnglishLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* jobCategoriesLookupRequest() {
  try {
    const response = yield call(API.jobCategoryLookupRequest);
    yield put(ACTION.jobCategoryLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* jobContractTypeLookupRequest() {
  try {
    const response = yield call(API.jobContractTypeLookupRequest);
    yield put(ACTION.jobContractTypeLookupReceive(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* lookupsSagasWatch() {
  yield takeLatest(type.GET_COUNTRIES_REQUEST, getCountries);
  yield takeLatest(type.GET_CITIES_REQUEST, getCities);
  yield takeLatest(type.GET_ALL_JOB_ROLES_LOOKUP_REQUEST, getAllJobRolesLookup);
  yield takeLatest(type.LOOKUP_SALARY_GRADES_REQUEST, lookupSalaryGrades);
  yield takeLatest(type.LOOKUP_RESIDENCIES_REQUEST, lookupResidencies);
  yield takeLatest(type.LOOKUP_NATIONALITIES_REQUEST, lookupNationalities);
  yield takeLatest(type.LOOKUP_LANGUAGES_REQUEST, lookupLanguages);
  yield takeLatest(type.LOOKUP_CAREER_LEVELS_REQUEST, lookupCareerLevels);
  yield takeLatest(type.LOOKUP_JOB_TITLES_REQUEST, lookupJobTitles);
  yield takeLatest(type.LOOKUP_COLLEGES_REQUEST, lookupColleges);
  yield takeLatest(type.LOOKUP_DEGREES_REQUEST, lookupDegrees);
  yield takeLatest(type.LOOKUP_COMPETENCIES_REQUEST, lookupCompetencies);
  yield takeLatest(
    type.LOOKUP_SALARY_GRADES_FILTER_REQUEST,
    lookupSalaryGradesFilter
  );
  yield takeLatest(type.LOOKUP_COMPANY_BRANCHES_REQUEST, lookupCompanyBranches);
  yield takeLatest(type.LOOKUP_CURRENCY_REQUEST, lookupCurrencies);
  yield takeLatest(
    type.LOOKUP_JOBTITLES_CATEGORIES_REQUEST,
    lookupJobtitlesCategories
  );
  yield takeLatest(type.LOOKUP_AGENTS_REQUEST, agentsLookups);
  yield takeLatest(type.LOOKUP_COMPANY_FIELDS_REQUEST, companyFieldsLookups);
  yield takeLatest(type.LOOKUP_GET_JOB_OPENINGS_REQUEST, jobOpeningsLookups);
  yield takeLatest(type.LOOKUP_GET_PIPELINE_FLOWS_REQUEST, pipelineFlowLookups);
  yield takeLatest(
    type.LOOKUP_GET_REJECTION_REASONS_REQUEST,
    rejectReasonsLookups
  );
  yield takeLatest(type.LOOKUP_COMPANY_SIZES_REQUEST, companySizesLookups);
  yield takeLatest(type.LOOKUP_COMPANY_TYPES_REQUEST, companyTypesLookups);
  yield takeLatest(type.HEAR_ABOUT_US_LIST_REQUEST, hearAboutUsLookups);
  yield takeLatest(type.CONTRACT_TYPE_REQUEST, getContractType);

  yield takeLatest(type.LOOKUP_CURRENCY_REQUEST, lookupCurrencies);
  yield takeLatest(type.COLLEGE_LOOKUP_REQUEST, collegeLookupRequest);
  yield takeLatest(type.DEGREE_LOOKUP_REQUEST, degreeLookupRequest);
  yield takeLatest(type.JOB_TITLE_LOOKUP_REQUEST, jobTitleLookupRequest);
  yield takeLatest(
    type.JOB_TITLE_FILTER_CATEGORY_LOOKUP_REQUEST,
    jobTitleFilterCategoryLookupRequest
  );
  yield takeLatest(
    type.JOB_TITLE_CATEGORY_LOOKUP_REQUEST,
    jobTitleCategoryLookupRequest
  );
  yield takeLatest(type.RESIDENCIES_LOOKUP_REQUEST, residenciesLookupRequest);
  yield takeLatest(
    type.LANGUAGES_WITHOUT_ENGLISH_LOOKUP_REQUEST,
    languagesWithOutEnglishLookupRequest
  );
  yield takeLatest(
    type.JOB_CATEGORY_LOOKUP_REQUEST,
    jobCategoriesLookupRequest
  );
  yield takeLatest(
    type.JOB_CONTRACT_TYPE_LOOKUP_REQUEST,
    jobContractTypeLookupRequest
  );
  yield takeLatest(type.COUNTRIES_LOOKUP_REQUEST, countriesLookupRequest);
  yield takeLatest(type.CITIES_LOOKUP_REQUEST, citiesLookupRequest);
  yield takeLatest(type.SKILLS_LOOKUP_REQUEST, skillsLookupRequest);

  yield takeLatest(type.USER_NATIONALITY_REQUEST, getUserNationality);
}
