import React, {useEffect, useState} from "react";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { setCurrentLang } from "../../store/Lang/action";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import history from "../../routes/History";
import logo from "../../assets/images/logo.png";
import bell from "../../assets/images/ic_bell.svg"
import "./Navbar.scss";
import DropDownMenuComponent from "../UserDropdownMenu/UserDropdownMenu";
import { updateURLocalization } from "../../utils/Shared";
import PopupNotification from "../popupNotifiaction/popupNotifiaction"
import {setNotificationSeenRequest} from "../../store/Notification/actions"
import { getAdminPortal } from "../../utils/redirection";


function NavbarComponent(props) {
  const lang = useSelector((state) => state.lang);
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const unseeenNotification = useSelector((state) => state.notification.popupNotification?.unseen_number);

  const { messages } = props.intl;
  const dispatch = useDispatch();
  const switcher = (lang) => {
    dispatch(setCurrentLang(lang === "ar" ? "en" : "ar"));
    updateURLocalization(lang === "ar" ? "en" : "ar")
  };

  const [popupNotification,setPopupNotification]=useState(false)
  useEffect(()=>{
    setPopupNotification(false)
  },[window.location.href])

  const handleNotificationBellClick=()=>{
    if(!popupNotification){
      dispatch(setNotificationSeenRequest())
     }
   
    setPopupNotification(!popupNotification)
  }
  const   handleClickAway=()=>{
    setPopupNotification(false)
  }
  return (
    <React.Fragment>
      <div className="navbar-wrapper navbar-light">
      <Navbar expand="lg" className="fixed-top">
        <Container className="nav-container-width">
          <Link to="/">
            <img src={logo} alt="HRCom logo" className="navbar-logo" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav activeKey={history.location.pathname}>
              <Nav.Item>
                <Nav.Link href="/" className="navItem">
                  {messages.navbar.home}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/jobs" className="navItem">
                  {messages.navbar.jobs}
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link href="/Companies" className="navItem">
                  {messages.navbar.companies}
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link href="/contact-us" className="navItem">
                  {messages.navbar.contactUs}
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Nav className="navItem text-dark  align-items-center">
              {is_authenticated ? (
                <div className="d-flex align-items-baseline justify-content-center">
                  <div className="px-4 cursor-pointer" >
                  <div className={unseeenNotification>0?"unseen-icon":""}>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <img src={bell} alt="bell" className="notification-bell" onClick={()=>handleNotificationBellClick()}/>
                    </ClickAwayListener>
                    </div>
                    </div>
                  <DropDownMenuComponent />
                </div>
              ) : (
                <>
                  <Link
                    className=" navItem px-3 mx-0"
                    to="/auth/login"
                  >
                    {messages.navbar.signIn}
                  </Link>
                  <Link
                    className="text-white navItem px-3  medium_font mx-0"
                    to="/auth/signup"
                  >
                    <button className="text-white signNav light-btn semi_bold_font">
                      {messages.navbar.signUp}
                    </button>
                  </Link>
                  <a
                    href={getAdminPortal()}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="navItem px-3  medium_font mx-0"
                  >
                    <button className="forCompanies light-btn semi_bold_font">
                      {messages.navbar.forCompanies}
                    </button>
                  </a>
                </>
              )}
              <button
                className="btn btn-link langBtn text-dark "
                id="en"
                onClick={() => switcher(lang)}
              >
                {messages.langBtn}
              </button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {popupNotification&&<PopupNotification />}
      </div>
    </React.Fragment>
  );
}

export default injectIntl(NavbarComponent);
