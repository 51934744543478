import History from "../routes/History";
import { getCandidatePortalHome } from "./redirection";

export const handleNotificationRedirection = (
  redirectionId,
  item_id = null,
  domain = null
) => {
  switch (parseInt(redirectionId)) {
    case 1:
      window.open(
        `${getCandidatePortalHome(domain)}/moreInformationQuestions?id=${item_id}`
      );
      break;
    case 2:
      window.open(`${getCandidatePortalHome(domain)}/preoffer?id=${item_id}`);
      break;
    case 3:
      window.open(`${getCandidatePortalHome(domain)}/offer?id=${item_id}`, "_blank");
      break;
    case 4:
      window.open(
        `${getCandidatePortalHome(domain)}/moreInformationQuestions?id=${item_id}`
      );
      break;
    case 5:
      History.push("/profile");
      break;
    case 6:
      window.open(`${getCandidatePortalHome(domain)}/interview/${item_id}`);
      break;
    case 7:
      window.open(`${getCandidatePortalHome(domain)}/interview/${item_id}`);
      break;
      case 25:
      window.open(`${getCandidatePortalHome(domain)}/contractor?id=${item_id}`, "_blank");
      case 31:
        window.location.href = "/myJobs?page=1&type=applied";
      break;
  }
};